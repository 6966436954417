import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Paiements_Class from '../../class/Paiements_Class'
import Moment from 'react-moment';

function PorteMonnaie() {

    const navigate = useNavigate();

    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [data, setData] = useState(false)

    const [balance, setBalance] = useState(0)

    const [transactions, setTransactions] = useState(false)

    const [addIban, setAddIban] = useState(false)

    const [loading, setLoading] = useState(false)

    const [isPayout, setIsPayout] = useState(true)

    const userPaiements = async () => {

        let results = await Paiements_Class.paiements()

        console.log(results)

        if (results.success) {

            setData(results.data)

            setBalance(results.data.balance - (results.data.kyc_paiement * 2))

            // A Supprimer
            // setData({
            //     "balance": "25.00",
            //     "balance_int": 25,
            //     "iban": "LT463500010003167710",
            //     "kyc_paiement" : "1",
            //     "kyc_active" : "0"
            // })
            // A Supprimer

            if (typeof results.transactions === "string") {
                setTransactions(results.transactions)
            } else {
                setTransactions(results.transactions.reverse())
            }

        } else {

            setFlash({ message: results.message, type: "error" })

        }

        window.scrollTo(0, 0)

    }

    const payout = async () => {

        setIsPayout(false)

        let results = await Paiements_Class.payout()

        console.log(results)

        if (results.success) {

            setFlash({ message: results.message, type: "succes" })

            userPaiements()

        } else {

            setFlash({ message: results.message, type: "error" })

        }

        window.scrollTo(0, 0)

        setIsPayout(true)

    }

    const editIban = async (e) => {

        e.preventDefault()

        setLoading(true)

        let inputs = e.target

        let iban = inputs.iban.value
        let name = inputs.name.value

        let results = await Paiements_Class.addIban(iban, name)

        if (results.success) {

            setFlash({ message: results.message, type: "succes" })

            setAddIban(false)

            userPaiements()

        } else {

            setFlash({ message: results.message, type: "error" })

        }

        window.scrollTo(0, 0)

        setLoading(false)

    }

    const masquerChaine = (chaine) => {
        const debut = chaine.slice(0, 4); // Les 4 premiers caractères
        const fin = chaine.slice(-4);    // Les 4 derniers caractères
        return `${debut}****${fin}`;
    }

    useEffect(() => {

        Func.session(context, navigate)

        Func.getFlash(setFlash)

        userPaiements()

        document.title = "Porte Monnaie - " + APP_NAME

    }, [])

    return (

        <div>

            <Header />

            <div className="profil">

                <HeaderBox />

                <div className='content pos-r'>

                    <div className="c">

                        <HeaderProfile />

                    </div>

                    <div className="mt30">

                        <div className="c pos-r">

                            <ProfileMenu data="porte-monnaie" />

                            {(flash !== '') && <p className={flash.type}>{flash.message}</p>}

                            {

                                data == false

                                    ?

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                    :

                                    <div className="paiements">

                                        <div className="bg6 p20 br10 mb20 flex ai-center">

                                            <div>
                                                <span className='d-block fw6 fz11'>Montant disponible</span>

                                                <p className='fz08 mt10'>Coût transfert 0,20 €</p>

                                            </div>

                                            <div className='m_mt_30'>

                                                <p className='fz3 ta-end m_ta_left'>{parseFloat(balance).toFixed(2)} €</p>

                                                {

                                                    parseFloat(balance) >= 1

                                                        ?

                                                        isPayout

                                                            ?

                                                            <span className='btn bg4 cl1' onClick={payout} title="Transférer vers mon compte">Transférer vers mon compte</span>

                                                            :

                                                            <span className='btn bg4 cl1'>Demande de transfert en cours ...</span>

                                                        :

                                                        <></>

                                                }

                                            </div>

                                        </div>

                                        {

                                            <div className="bg6 p20 br10 mb20">
                                                <div className="flex ai-center m_flex">
                                                    <div>
                                                        <span className='d-block'>Procédure KYC obligatoire { data.kyc_active == true && <span className='cl4'>(VALIDÉ)</span> }</span>

                                                        { parseFloat(balance) >= 2 && data.kyc_active == false && <p className='fz08 mt10'>Pour transférer ton argent vers ton compte bancaire, il est nécessaire de vérifier ton identité en passant par la procédure KYC.</p> }

                                                    </div>
                                                    <Link to={'/mon-profile/identitee'} className='btn bg4 cl5 ml10'>Accéder</Link>
                                                </div>
                                            </div>

                                        }

                                        <div className="bg6 p20 br10 mb20">
                                            <div className="flex ai-center m_flex">
                                                {
                                                    data.iban == 0

                                                        ?

                                                        <>
                                                            <span className='d-block'>Ajouter un IBAN</span>
                                                        </>
                                                        :

                                                        <span className='d-block'>IBAN enregistré ({ masquerChaine(data.iban) })</span>

                                                }

                                                <span className='btn bg4 cl5' onClick={e => setAddIban(!addIban)}>{data.iban == 0 ? 'Ajouter' : 'Modifier'}</span>

                                            </div>
                                        </div>

                                        {

                                            addIban

                                            &&

                                            <form method='POST' className='gla-form bg6 p20 br10 mb20' onSubmit={(e) => editIban(e)} id='parametres'>

                                                <h3 className='mb30 fw3'>Mon compte</h3>


                                                <label className='col-5'>
                                                    <span>Nom du titulaire du compte</span>
                                                    <div><input type='text' name='name' placeholder="Nom et prénom" minLength="6" maxLength="64" required={true} style={{ border: '1px solid #666', width: '300px' }} /></div>
                                                </label>

                                                <label className='col-5'>
                                                    <span>IBAN</span>
                                                    <div><input type='text' name='iban' placeholder='FR********' minLength="27" maxLength="34" required={true} style={{ border: '1px solid #666', width: '300px' }} /></div>
                                                </label>

                                                {

                                                    loading

                                                        ?

                                                        <div className="lds-dual-ring col"></div>

                                                        :

                                                        <div className="ta-end">
                                                            <input type="submit" name="submit" value="Enregistrer" className="btn cl1 bg4 brc4 brc2solid fw5" />
                                                        </div>

                                                }

                                            </form>

                                        }

                                        <h3 className='mb20 fw5'>Demandes de paiement</h3>

                                        {

                                            typeof transactions === "string"

                                                ?

                                                <p className='fz09'>{transactions}</p>

                                                :

                                                <>



                                                    {

                                                        transactions.map(transaction =>

                                                            <div className="trans bg6 cl2 pl20" key={transaction.Id}>

                                                                <div className='infos'>

                                                                    <span className='t cl2 fw6'>{parseFloat(transaction.CreditedFunds.Amount / 100).toFixed(2)} €</span>
                                                                    { transaction.Fees.Amount > 20 && <span className='t cl2'>Frais KYC {(transaction.Fees.Amount - 20) / 100} €</span> }

                                                                    {transaction.Status == 'CREATED' && <span className='t cl2'>EN COURS</span>}
                                                                    {transaction.Status == 'SUCCEEDED' && <span className='t' style={{ color: '#6eab69' }}>VALIDÉ</span>}
                                                                    {transaction.Status == 'FAILED' && <span className='t' style={{ color: 'rgba(255,0,0,0.9)' }}>REFUSÉ</span>}
                                                                    {transaction.Status == 'FAILED' && <span className='fz08 col-5'>({transaction.ResultMessage})</span>}

                                                                </div>

                                                                {transaction.CreationDate !== 0 && <span className='date'><Moment locale="fr" format='DD MMMM Y' unix={transaction.CreationDate}>{transaction.CreationDate}</Moment></span>}

                                                            </div>

                                                        )

                                                    }

                                                </>

                                        }

                                    </div>

                            }

                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}

export default PorteMonnaie