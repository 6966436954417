import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Paiements_Class from '../../class/Paiements_Class'

import User from '../../class/User'

function Paiements() {

    const navigate = useNavigate();

    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [cartes, setCartes] = useState(false)

    const getCartes = async () => {

        let results = await Paiements_Class.user_cards()

        if(results.data){
                
            setCartes(results.data)

        }else{

            setCartes(results.message)

        }

    }

    const deleteCard = async (id) => {
        
        let results = await Paiements_Class.deleteCard(id)

        if(results.data){
                
            setFlash({"message" : results.message, "type": "succes"})

            setCartes(false)

            setCartes(results.data)

        }else{

            setFlash({"message" : results.message, "type": "error"})

        }

    }

    useEffect(() => {

        Func.session(context, navigate)

        Func.getFlash(setFlash)

        getCartes()

        document.title = "Paiements - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">
                    
                    <HeaderProfile />

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        <ProfileMenu data="paiements"/>

                        {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

                        <div className="paiements">
                            <div className="bg6 p30 br10 mb20">

                                <span className='fw5 d-block'>Options de paiement</span>

                                <div>

                                        {

                                            cartes

                                            ?

                                                typeof cartes == "string"

                                                ?

                                                    <p className='fz09'>{ cartes }</p>

                                                :

                                                cartes.map(carte =>

                                                    carte.Active

                                                    &&

                                                    <div className="flex m_flex ai-center mt20" key={carte.Id}>
                                                        
                                                        <div>
                                                            <span className='d-block'>Carte { carte.CardProvider } : { carte.Alias }</span>
                                                            <span>Expiration { carte.ExpirationDate.substring(0,2) + '/20' + carte.ExpirationDate.substring(2,4) }</span>
                                                        </div>
                                                        
                                                        <span className='btn bg4 cl5' onClick={e => deleteCard(carte.Id)}>Supprimer</span>

                                                    </div>
                                                
                                                )

                                            :

                                            <div className='mt50 mb50'>
                                                <div className="lds-dual-ring col"></div>
                                            </div>

                                        }
                                </div>

                            </div>

                            <div className="bg6 p30 br10 mb20">

                                <div className="flex ai-center m_flex">
                                    <span className='d-block'>Ajouter une carte</span>
                                    <Link to={'ajouter_carte'} className='btn bg4 cl5'>Ajouter</Link>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default Paiements