import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { APP_NAME, WEBROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import { Text } from '../../constants/Text'

import Paiements_Class from '../../class/Paiements_Class'

import '../../imports/credit_card.css'

import mangoPay from 'mangopay-cardregistration-js-kit';

function PaiementsAjouterCarte() {

    const navigate = useNavigate()

    const context = useContext(Context)
    
    const [loading, setLoading] = useState(false)

    const [cardRegistrations, setCardRegistrations] = useState(false)

    const [error, setError] = useState('')

    const getCardRegistrations = async () => {

        let results = await Paiements_Class.cardRegistrations()
    
        if(results.success){

            setCardRegistrations(results.data)

        }else{

            navigate('/404')

        }
    
    }

    const createCard = async (e) => {

        e.preventDefault()
        
        setLoading(true)
        
        let input = e.target
    
        let cardExpirationDate = input.month.value + input.year.value

        let cardData = {
            cardNumber: input.cardNumber.value,
            cardExpirationDate: cardExpirationDate, 
            cardCvx: input.cardCvx.value,
            cardType: 'CB_VISA_MASTERCARD'
        }

        // Dev
        // mangoPay.cardRegistration.baseURL = 'https://api.sandbox.mangopay.com'
        // mangoPay.cardRegistration.clientId = 'sistersgreentest'

        // Prod
        mangoPay.cardRegistration.baseURL = 'https://api.mangopay.com'
        mangoPay.cardRegistration.clientId = 'sistersgreenprod'

        const CardType = cardRegistrations.CardType
        const RedirectUrl = "/mon-profile/paiements";

        mangoPay.cardRegistration.init({
            cardRegistrationURL: cardRegistrations.CardRegistrationURL, 
            preregistrationData: cardRegistrations.PreregistrationData, 
            accessKey: cardRegistrations.AccessKey,
            Id: cardRegistrations.Id
        })
    
        mangoPay.cardRegistration.registerCard(
            cardData, 
            function(res) {

                Func.setFlash(Text.carte_ajoutee)

                navigate('/mon-profile/paiements')
        
            },
            function(res) {

                setLoading(false)

                setError("Impossible d'ajouter cette carte, " + res.ResultMessage)

                // console.log(res.ResultCode)
                // console.log(res.ResultMessage)

                console.log(res)
        
            }
        )

    }

    useEffect(() => {

        Func.session(context, navigate)

        document.title = "Ajouter une carte de paiement - " + APP_NAME

        getCardRegistrations()

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">
                    
                    <HeaderProfile />

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        <ProfileMenu data="paiements"/>

                        <div className="paiements">

                            <div className="bg6 p30 br10 mb20">

                                {

                                    cardRegistrations

                                    ?

                                    <>

                                        <form className="credit-card" onSubmit={(e) => createCard(e)}>
                                            <div className="form-header">
                                                <h4 className="title">Détails de la carte</h4>
                                            </div>
                                            
                                            <div className="form-body">

                                                {(error !== '') && <p className="error">{error}</p>}

                                                <input type="text" name='cardNumber' className="card-number" placeholder="Numéro de la carte" maxLength={16} minLength={16} required={true}/>
                                            
                                                <div className="date-field">
                                                <div className="month">
                                                    <select name="month" required={true}>
                                                    <option value="01">Janvier</option>
                                                    <option value="02">Février</option>
                                                    <option value="03">Mars</option>
                                                    <option value="04">Avril</option>
                                                    <option value="05">Mai</option>
                                                    <option value="06">Juin</option>
                                                    <option value="07">Juillet</option>
                                                    <option value="08">Août</option>
                                                    <option value="09">Septembre</option>
                                                    <option value="10">Octobre</option>
                                                    <option value="11">Novembre</option>
                                                    <option value="12">Décembre</option>
                                                    </select>
                                                </div>

                                                <div className="year">
                                                    <select name="year" required={true}>
                                                    <option value="23">2023</option>
                                                    <option value="24">2024</option>
                                                    <option value="25">2025</option>
                                                    <option value="26">2026</option>
                                                    <option value="27">2027</option>
                                                    <option value="28">2028</option>
                                                    <option value="29">2029</option>
                                                    <option value="30">2030</option>
                                                    <option value="31">2031</option>
                                                    <option value="32">2032</option>
                                                    <option value="33">2033</option>
                                                    <option value="34">2034</option>
                                                    </select>
                                                </div>
                                                </div>
                                            
                                                <div className="card-verification">
                                                <div className="cvv-input">
                                                    <input type="text" name='cardCvx' placeholder="CVV" maxLength={3} minLength={3} required={true}/>
                                                </div>
                                                <div className="cvv-details">
                                                    <p>Ajouter les 3 chiffres</p>
                                                </div>
                                                </div>
                                            
                                                {
                                                    
                                                    loading

                                                    ?

                                                    <div className='flex jc-center mt50 mb30 col-10 pt30'>
                                                        <div className="lds-dual-ring col"></div>
                                                    </div>

                                                    :

                                                    <button type="submit" className="proceed-btn bg4 cl1">Ajouter</button>

                                                }

                                            </div>
                                        </form>

                                        <div class="flex m_flex jc-center col-8 m0a"><img src="/image/paiement-securise2.webp" class="col-3 m_col_8 mt20" /></div>
                                        <p class="fz07 d-block ta-center mt10">Vous êtes sur un serveur de paiement sécurisé<br /> par les normes ssl (https).</p>


                                    </>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                                }

                            </div>

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default PaiementsAjouterCarte