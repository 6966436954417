import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, useParams } from 'react-router-dom';

import { Header, Footer, HeaderBox, Article } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile'

import { APP_NAME } from '../../constants/general'

import Annonces from '../../class/Annonces';

function Profile() {

    const { id } = useParams()

    const navigate = useNavigate()

    const [articles, setArticles] = useState(false)

    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(24)

    const getArticles = async (limit, load = true) => {

        if (load) window.scrollTo(0, 0)

        let results = await Annonces.annoncesByUser(id, limit)

        setLoading(false)

        if (results.success) {

            setArticles(results.data)

        } else {

            if (results.empty) {

                setArticles(results.message)

            } else {

                navigate('/404')

            }

        }

    }

    const voirPlus = () => {

        setLoading(true)

        getArticles({ limit: limit }, false)

        setLimit(limit + 12)

    }

    const copierLien = () => {

        const text = 'https://sistersgreen.fr/profile/' + id

        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()

        alert('Lien profil copié')

    }

    useEffect(() => {

        getArticles({ limit: 12 })

        document.title = "Utilisateur - " + APP_NAME

    }, [])

    return (

        <div>

            <Header />

            <div className="profil">

                <HeaderBox />

                <div className='content pos-r'>

                    <div className="c">

                        <div className='m_flex flex ai-center'>

                            <a href="#" className='cl2 textUnd fz09'>Profil</a>

                            <span className='cur_p' title='Copier lien profil' onClick={() => copierLien()}><img src='/image/share.svg' /></span>

                        </div>

                        <HeaderProfile id={id} />

                    </div>

                    <div className="mt30">

                        <div className="c pos-r">

                            <ProfileMenu data="articles" id={id} />

                            {

                                articles

                                    ?

                                    typeof articles == 'object'

                                        ?

                                        <>

                                            <div className="grid4 articles mb30 m_p0">

                                                {articles.map(element => <Article data={element} key={element.idA} />)}

                                            </div>

                                            {loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>}

                                            {

                                                articles.length % 12 == 0

                                                &&

                                                <div className='flex jc-center mt30'>
                                                    <span className='btn bg4 cl1' onClick={voirPlus}>Voir plus</span>
                                                </div>

                                            }

                                        </>

                                        :

                                        <p className='mt30 bg7 p20 br10'>{articles}</p>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}

export default Profile