import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link, Navigate } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { WEBROOT, APP_NAME, AVATAR_ROOT } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import User from '../../class/User'

function Parametres() {

    const navigate = useNavigate();

    const context = useContext(Context)

    const [flash, setFlash] = useState('')
    const [flashPassword, setFlashPassword] = useState('')

    const [loading, setLoading] = useState(false)

    const [infos, setInfos] = useState(false)

    const [afficher_ville, setAfficher_ville] = useState(false)

    const [editPassword, setEditPassword] = useState(false)

    const [password, setPassword] = useState('password')

    const myInfos = async () => {

        let results = await User.session(true)

        if(results.success){

            setInfos(results.data)

            setAfficher_ville(results.data.afficher_ville == 1 ? true : false)

        }

    }

    const change_afficher_ville = async (e) => {

        setAfficher_ville(e.target.checked ? true : false)

    }

    const modifier = async (e) =>{

        e.preventDefault()

        setLoading(true)
    
        let inputs = e.target
    
        const results = await User.modifierInformations(inputs)
    
        setLoading(false)

        if(results.success){

            setFlashPassword('')
            
            setFlash({type: 'succes', message: results.message})

            localStorage.setItem('name', inputs.name.value)
    
        }else{

            setFlashPassword('')

            setFlash({type: 'error', message: results.message})
    
        }

        window.scrollTo(0, 0)
    
    }

    const renvoyerValidation = async (e) =>{
    
        const results = await User.renvoyerValidation()

        if(results.success){

            setFlashPassword('')

            setFlash({type: 'succes', message: results.message})
    
        }

        window.scrollTo(0, 0)
    
    }

    const uploadAvatar = async (e) => {

        let file = e.target.files[0]

        if(file.size == 0 || file.name == null) setFlash({ type: 'error', message: "Impossible d'ajouter cette photo une erreur est servenu" })
    
        setLoading(true)
    
        let results = await User.photoUpload(file)
    
        setLoading(false)

        if (results.success && results.avatar !== undefined){
    
            localStorage.setItem('avatar', results.avatar)

            document.querySelectorAll('.avatar_img').forEach(elem => {

                elem.src = AVATAR_ROOT + results.avatar + '.jpg?t=' + Date.now()

            })

            setFlash('')

        }else{
    
            setFlash({type: 'error', message: results.message})
            
        }
    
    }
    
    const uploadPhotosInput = () => {
    
        document.querySelector('#uploadPhotosInput').click()
    
    }

    const modifierMotdepasse = async (e) =>{

        e.preventDefault()

        setLoading(true)
    
        let inputs = e.target
    
        const results = await User.modifierMotdepasse(inputs)

        setLoading(false)
    
        if(results.success){

            e.target.password_old.value = ''
            e.target.password.value = ''
            e.target.password_r.value = ''

            setFlash('')

            setFlashPassword({type: 'succes', message: results.message})
    
        }else{

            setFlash('')

            setFlashPassword({type: 'error', message: results.message})
    
        }
    
    }

    useEffect(() => {
        
        Func.session(context, navigate)

        Func.getFlash(setFlash)

        myInfos()

        document.title = "Parametres - " + APP_NAME

    }, [])

    return (

        <div>

         <Header />
                
            <div className="profil">

            <HeaderBox />

            <div className='content pos-r'>

                <div className="c">
                    
                    <HeaderProfile />

                </div>

                <div className="mt30">

                    <div className="c pos-r">

                        {(flash !== '') && <p className={ flash.type }>{ flash.message }</p>}

                        <ProfileMenu data="parametres"/>

                        <div className="settings">
                                    
                            <div className='bg6 p30 br10 mb20'>

                                <label className="flex ai-center m_flex">
                                    <span>Photo de profil</span>

                                    <div className="flex ai-center m_flex">
                                        <div className="av medium mr20">

                                            <img src={ Func.avatar(localStorage.getItem('avatar')) } className="avatar_img"/>

                                            {
                                                loading

                                                ?
                                                
                                                <div className="lds-dual-ring col"></div>

                                                :

                                                <input type="file" id="uploadPhotosInput" onChange={e => uploadAvatar(e)} style={{ display: 'none'}}/>

                                            }
                                            
                                        </div>
                                        <span className="btn bg4 cl1 brc1solid brc4 mt0">Choisir une photo</span>
                                    </div>
                                </label>

                            </div>

                            <div className="bg6 p30 br10 mb20">
                                
                                <form method='POST' className='gla-form' onSubmit={(e) => modifier(e)} id='parametres'>

                                    <label>
                                        <span>Nom d'utilisateur</span>
                                        <input name='name' placeholder="Nom d'utilisateur ..." required={true} defaultValue={infos.name} maxLength={128}/>
                                    </label>

                                    <label>
                                        <span>Nom prénom</span>
                                        <input name='names' placeholder='Nom et prénom ...' required={true} defaultValue={infos.names} maxLength={128}/>
                                    </label>

                                    <h3 className='mb30 fw3'>Adresse</h3>

                                    <label>
                                        <span>Pays</span>

                                        <select name='pays' required={true}>
                                            <option selected={ infos.pays == "France" ? true : false }>France</option>
                                        </select>
                                    </label>

                                    <label>
                                        <span>Ville</span>
                                        <input name='ville' placeholder='Ex: Paris' required={true} defaultValue={infos.ville}/>
                                    </label>

                                    {/* <div className='mb20'>
                                        <input type="checkbox" name='afficher_ville' checked={ afficher_ville } onChange={ e => change_afficher_ville(e) }/>
                                        <span className='mr20 fw3'>Afficher la ville dans le profil</span>
                                    </div> */}

                                    <label>
                                        <span>Adresse, Rue</span>
                                        <input name='rue' placeholder='Ex: Allée André-Breton' required={true} defaultValue={infos.rue}/>
                                    </label>

                                    <label>
                                        <span>Code postal</span>
                                        <input type='number' name='postal' placeholder='Ex: 75001' required={true} defaultValue={infos.postal} min={0}/>
                                    </label>

                                    <label>
                                        <span className='fw5 mr30'>Numéro de téléphone</span>
                                        <input type='text' name='numero' placeholder='Ex: 06 00 00 00 00' pattern="[0-9]+" required={true} defaultValue={infos.numero}/>
                                    </label>

                                    {
                                        
                                        loading

                                        ?
                                        
                                        <div className="lds-dual-ring col"></div>

                                        :

                                        <div className="ta-end mt20">
                                            <input type="submit" name="submit" value="Enregistrer" className="btn cl1 bg4 brc4 brc2solid fw5" />
                                        </div>

                                    }

                                </form>
                            </div>

                            <div className="bg6 p30 br10 mb20">

                                <div className="flex ai-center">

                                    <span className='fw5 mr30'>{infos.email}</span>

                                    { infos.email_token == 0 ? <span className='fw6 fz12 cl4 mr20 l_mj'>Vérifié</span> : <span className='fw6 fz13 l_mj'><span className='cl2 btn' onClick={e => renvoyerValidation(e) }>renvoyer le mail de vérification</span></span>}
                                
                                </div>

                            </div>

                            {

                                editPassword

                                &&

                                <form method='POST' className='gla-form bg6 p30 br10 mb20' onSubmit={(e) => modifierMotdepasse(e)} id='parametres'>

                                    <h3 className='mb30 fw3'>Modifier mon mot de passe</h3>

                                    {(flashPassword !== '') && <p className={ flashPassword.type }>{ flashPassword.message }</p>}

                                    <label>
                                        <span>Mot de passe actuel</span>

                                        <div className='input_eye'>

                                            <input type={password} name='password_old' placeholder='********' minLength="6" maxLength="32" required={true} style={{border: '1px solid #666', width: '300px'}}/>

                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{opacity: '0.7'}} onClick={e => password == 'password' ? setPassword('text') :  setPassword('password') }><path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z"/></svg>

                                        </div>
                                        
                                    </label>

                                    <label>
                                        <span>Nouveau mot de passe</span>
                                        <div><input type='password' name='password' placeholder='********' minLength="6" maxLength="32" required={true} style={{border: '1px solid #666', width: '300px'}}/></div>
                                    </label>

                                    <label>
                                        <span>Retappez le nouveau mot de passe</span>
                                        <div><input type='password' name='password_r' placeholder='********' minLength="6" maxLength="32" required={true} style={{border: '1px solid #666', width: '300px'}}/></div>
                                    </label>

                                    {

                                        loading

                                        ?
                                        
                                        <div className="lds-dual-ring col"></div>

                                        :

                                        <div className="ta-end">
                                            <input type="submit" name="submit" value="Enregistrer" className="btn cl1 bg4 brc4 brc2solid fw5" />
                                        </div>

                                    }

                                </form>

                            }

                            <div className="bg6 p30 br10">

                            {

                                !editPassword

                                &&

                                <div className="flex mb20 m_flex">

                                    <span className='fw5 mr30'>Mot de passe</span>
                                        
                                    <span className='btn bg4 cl5' onClick={ e => setEditPassword(!editPassword)}>Modifier</span>

                                </div>

                            }

                            
                                <div className="flex m_flex">
                                    
                                    <span className='fw5'>Supprimer mon compte</span>
                                        
                                    <Link to={'/mon-profile/supprimer-mon-compte'} className='btn bg4 cl1'>Supprimer</Link>

                                </div>


                            </div>

                        </div>

                    </div>
                </div>
            </div>

            </div>

            <Footer/>

        </div>

    )

}

export default Parametres