import React, { useState, useEffect, useContext } from 'react';

import { useNavigate, Link } from 'react-router-dom';

import { Header, Footer, HeaderBox } from '../../components/imports'

import { Article, HeaderProfile, ProfileMenu } from '../../components/profile-prive'

import { APP_NAME } from '../../constants/general'

import { Context } from '../../class/Context'

import Func from '../../class/Func'

import Annonces from '../../class/Annonces';

function Articles() {

    const navigate = useNavigate()

    const context = useContext(Context)

    const [flash, setFlash] = useState('')

    const [articles, setArticles] = useState(false)

    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(24)

    const getArticles = async (limit) => {

        setLoading(false)

        let results = await Annonces.mesAnnonces(limit)

        setArticles(results)

    }

    const voirPlus = () => {

        setLoading(true)

        getArticles({ limit: limit })

        setLimit(limit + 12)

    }

    useEffect(() => {

        Func.getFlash(setFlash)

        Func.session(context, navigate)

        window.scrollTo(0, 0)

        getArticles({ limit: 12 })

        document.title = "Mes articles - " + APP_NAME

        document.body.classList.remove('mn')

    }, [])

    return (

        <div>

            <Header />

            <div className="profil">

                <HeaderBox />

                <div className='content pos-r'>

                    <div className="c">

                        <HeaderProfile />

                    </div>

                    <div className="mt30">

                        <div className="c pos-r">

                            {(flash !== '') && <p className={flash.type}>{flash.message}</p>}

                            <ProfileMenu data="articles" />

                            {

                                articles

                                    ?

                                    articles.data

                                    &&

                                    <>

                                        <div className="grid4 articles mb30 m_p0">

                                            {articles.data.map(element => <Article data={element} key={element.idA} />)}

                                        </div>

                                        {loading && <div className='flex jc-center mt50 mb50'><div className="lds-dual-ring col"></div></div>}

                                        {

                                            articles.data.length % 12 == 0

                                            &&

                                            <div className='flex jc-center mt30 m_flex'>
                                                <span className='btn bg4 cl1' onClick={voirPlus}>Voir plus</span>
                                            </div>

                                        }

                                    </>

                                    :

                                    <div className='flex jc-center mt50 mb50'>
                                        <div className="lds-dual-ring col"></div>
                                    </div>

                            }

                        </div>
                    </div>
                </div>

            </div>

            <Footer />

        </div>

    )

}

export default Articles